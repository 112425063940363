
// FONT

$font-stack: 'Inconsolata', monospace;
$font-size-base: 16px;

// MISC

$totalcolumns: 12;


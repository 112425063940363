/* https://google-webfonts-helper.herokuapp.com/fonts/inconsolata?subsets=latin */

/* inconsolata-regular - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/site/fonts/inconsolata-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Inconsolata Regular'), local('Inconsolata-Regular'),
       url('/assets/site/fonts/inconsolata-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/site/fonts/inconsolata-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/site/fonts/inconsolata-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/site/fonts/inconsolata-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/site/fonts/inconsolata-v16-latin-regular.svg#Inconsolata') format('svg'); /* Legacy iOS */
}

/* inconsolata-700 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/site/fonts/inconsolata-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Inconsolata Bold'), local('Inconsolata-Bold'),
       url('/assets/site/fonts/inconsolata-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/site/fonts/inconsolata-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/site/fonts/inconsolata-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/site/fonts/inconsolata-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/site/fonts/inconsolata-v16-latin-700.svg#Inconsolata') format('svg'); /* Legacy iOS */
}

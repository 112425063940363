// Ruby Sass is deprecated and will be unmaintained as of 26 March 2019.

// * If you use Sass as a command-line tool, we recommend using Dart Sass, the new
//   primary implementation: https://sass-lang.com/install

// * If you use Sass as a plug-in for a Ruby web framework, we recommend using the
//   sassc gem: https://github.com/sass/sassc-ruby#readme

// * For more details, please refer to the Sass blog:
//   http://sass.logdown.com/posts/7081811

// Body resets
//
// Update the foundational and global aspects of the page.

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  line-height: 1.5;
  @media (min-width: 38em) {
    font-size: 18px;
  }
}

body {
  color: #515151;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

// No `:visited` state is required by default (browsers will use `a`)
a {
  color: #268bd2;
  text-decoration: none;

  // `:focus` is linked to `:hover` for basic accessibility
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  strong {
    color: inherit;
  }
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

table {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 85%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid #e5e5e5;
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}

.badge-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .column {
    flex: 33.33%;
    .img-link {
      display: inline-block;
      .badge {
        max-width: 160px;
        margin: 6px 6px 6px 6px;
        float: left;
      }
    }
  }
}

.scrolling-wrapper {
  margin-top: 43px;
  padding: 5px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  .img-link {
    flex: 0 0 auto;
    display: inline-block;
    .grayscale {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      /* Firefox 3.5+, IE10 */
      filter: gray;
      /* IE6-9 */
      -webkit-filter: grayscale(100%);
      /* Chrome 19+ & Safari 6+ */
      -webkit-transition: all .6s ease;
      /* Fade to color for Chrome and Safari */
      -webkit-backface-visibility: hidden;
      /* Fix for transition flickering */
      height: 48px;
      margin: 1px 5px 1px 5px;
      float: left;
    }
    .grayscale:hover {
      filter: none;
      -webkit-filter: grayscale(0%);
      height: 48px;
      margin: 1px 5px 1px 5px;
      float: left;
    }
  }
}


//////////////////////////////


// body as wrapper

body {
  width: 80%;
  max-width: 960px;
  margin: 0 auto;
  padding-top: 2em;
  padding-bottom: 2em;
}

// actual column classes

.col-12 {
  @include col(12);
}

.col-8 {
  @include col(8);
}

.col-6 {
  @include col(6);
}

.col-4 {
  @include col(4);
}

.col-4, .col-6, .col-8, .col-12 {
  @media screen AND (max-width: 959px) {
    width: 100%;
  }
  @media screen AND (min-width: 960px) {
    display: inline-block;
    float: left;
  }
}

/* set body font-sizes in media queries, boom, font-scaling done! */

body,pre {
  font-size: $font-size-base;
  line-height: $font-size-base + 6;
  font-family: $font-stack;
}

aside {

  margin-bottom: 1em;

  .pre {
    white-space: pre;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &:before {
      content: "\0000a0\0000a0\0000a0";
    }
  }

  li {
    display: inline-block;

    &:after {
      content: "\0000a0";
    }
  }

  // li:before {
  //  content: "\0000a0\0000a0\0000a0\0000a0";
  // }
}

article {
  margin: 0 0 1.5em 0;

  p {
    margin-top: 0.4em;
  }

  @media screen AND (min-width: 960px) {
    width: 80ch;
  }
}

.read-more {
  @media screen AND (min-width: 960px) {
    width: 80ch;
  }
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
  margin-top: 1.5em;

  a:link, a:visited {
    color: inherit;
  }

  span:before {
    content: "----------------------------\00a0";
    margin-left: -50%;
  }

  span:after {
    content: "\00a0----------------------------";
    margin-right: -50%;
  }

  span:before, span:after {
  }

}

h1 {
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 0.5em;

  a:link, a:visited {
    color: inherit;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
  margin-bottom: 1em;
}

nav ul li {
  display: block;
}

footer {
  clear: both;
  min-height: 4em;
}

.pagination {
  margin-bottom: 2em;
}

.stress {
  background-color: #F0F8FF;
}